export default {
    user: {
        loggedIn: localStorage.getItem('LOGGEDIN') ? true : false,
        isAdmin: localStorage.getItem('USERROLE') === 'Admin' ? true : false,
        token: localStorage.getItem('TOKEN') ? localStorage.getItem('TOKEN') : null,
        data: JSON.parse(localStorage.getItem('USER')) ? JSON.parse(localStorage.getItem('USER')) : {},
    },

    users: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    drivers: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    allDriverOrders: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    fuelTypes: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    messages: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    notifications: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

}