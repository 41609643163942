const ROLE = {
    ADMIN: 'admin',
};

export function setUser(state, user) {
    if (user) {
        state.user.data = user;
        state.user.loggedIn = true,
            state.user.isAdmin = user.ur === ROLE.ADMIN;

        // Save user data to localStorage
        localStorage.setItem('USER', JSON.stringify(state.user.data));

        if (state.user.isAdmin) {
            localStorage.setItem('USERROLE', 'Admin');
        }
        localStorage.setItem('LOGGEDIN', state.user.loggedIn)
    } else {
        // Clear user data from localStorage
        localStorage.removeItem('USER');
        localStorage.removeItem('USERROLE');
        localStorage.removeItem('LOGGEDIN');
    }
}

export function setToken(state, token) {
    state.user.token = token;
    if (token) {
        localStorage.setItem('TOKEN', token);
    } else {
        localStorage.removeItem('TOKEN')
    }
}

export function setUsers(state, [loading, data = null]) {
    if (data) {
        state.users = {
            ...state.users,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.users.loading = loading;
}

export function setDrivers(state, [loading, data = null]) {
    if (data) {
        state.drivers = {
            ...state.drivers,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.drivers.loading = loading;
}

export function setFuelType(state, [loading, data = null]) {
    if (data) {
        state.fuelTypes = {
            ...state.fuelTypes,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.fuelTypes.loading = loading;
}

export function setAllDriverOrders(state, [loading, data = null]) {
    if (data) {
        state.allDriverOrders = {
            ...state.allDriverOrders,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.allDriverOrders.loading = loading;
}

export function setMessages(state, [loading, data = null]) {
    if (data) {
        state.messages = {
            ...state.messages,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.messages.loading = loading;
}

export function setNotification(state, [loading, data = null]) {
    if (data) {
        state.notifications = {
            ...state.notifications,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.notifications.loading = loading;
}

