<script setup>
import { onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"

const title = "لیستی ئۆتۆمبێلی بەکارهێنەر"
const pageTitle = "بەکارهێنەران"

const route = useRoute()
const userId = route.params.userId

const userVehicles = ref([])

onMounted(async () => {
  const { data } = await store.dispatch("getVehiclesByUser", userId)
  userVehicles.value = {
    ...data.data,
  }
})

const parseColor = (color) => {
  try {
    const parsedColor = JSON.parse(color)
    return parsedColor
  } catch (error) {
    console.error("Error parsing color:", error)
    return { colorCode: "", colorName: "" }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="userVehiclesList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیستی ئۆتۆمبێلی بەکارهێنەر</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2"></div>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="userVehiclesTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <th data-sort="vehicle_details">وردەکاری ئۆتۆمبێل</th>
                      <th data-sort="fuel_type">جۆری سووتەمەنی</th>
                      <th data-sort="plate">تابلۆی ئۆتۆمبێل</th>
                      <th data-sort="created_at">بەرواری ناو تۆمارکردن</th>
                    </tr>
                  </thead>

                  <tbody class="list">
                    <tr v-for="(item, index) of userVehicles" :key="index">
                      <td>
                        <div class="d-flex">
                          <div class="flex-shrink-0 p-1">
                            <img
                              :src="item.vehicle_image"
                              alt=""
                              width="200"
                              class="img-thumbnail"
                              data-holder-rendered="true"
                            />
                          </div>
                          <div class="flex-grow-1 ms-3">
                            <h5 class="fs-15">
                              <router-link to="" class="link-primary"
                                >{{ item.vehicle_brand }}
                              </router-link>
                            </h5>

                            <p class="text-muted mb-0">
                              ڕەنگی ئۆتۆمبێل:
                              <span
                                :style="{
                                  color: parseColor(item.vehicle_color)
                                    .colorCode,
                                  textShadow: '0px 0px 1px #000',
                                }"
                                class="fw-medium px-1"
                                >{{
                                  parseColor(item.vehicle_color).colorName
                                }}</span
                              >
                            </p>

                            <p class="text-muted mb-0">
                              جۆری ئۆتۆمبێل:
                              <span class="fw-medium">{{
                                item.vehicle_type
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>{{ item.fuel_type }}</td>
                      <td>
                        <strong>جۆری پلاکی:</strong>
                        {{ item.plate_type }}<br />
                        <p class="text-muted mb-0">
                          <strong>ڕەنگی پلاکی:</strong>
                          <span
                            :style="{
                              color: parseColor(item.plate_color).colorCode,
                              textShadow: '0px 0px 1px #000',
                            }"
                            class="fw-medium px-1"
                            >{{ parseColor(item.plate_color).colorName }}</span
                          >
                        </p>
                        <br />
                        <strong>ژمارەی پلاکی:</strong>
                        {{ item.plate_number }}<br />
                      </td>
                      <td>
                        {{ item.created_at }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
