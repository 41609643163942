<template>
  <footer class="footer">
    <BContainer fluid>
      <BRow>
        <BCol col sm="6"> {{ new Date().getFullYear() }} © Speed Fuel. </BCol>
        <BCol col sm="6">
          <div class="text-sm-end d-none d-sm-block">
            Design & Develop by Progrun Team
          </div>
        </BCol>
      </BRow>
    </BContainer>
  </footer>
</template>
