<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"
import { digitsEnToAr } from "@persian-tools/persian-tools"

import store from "../../state/store"

const drivers = computed(() => store.state.drivers)

import useDrivers from "../../use/useDrivers"
import useDebouncedFunction from "../../use/useDebouncedFunction"

import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const { driverStatus, search, perPage, sortField, sortDirection, getDrivers } =
  useDrivers()
const { handleInput } = useDebouncedFunction(getDrivers)

const emit = defineEmits(["clickEdit", "clickWallet"])

onMounted(() => {
  getDrivers()
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getDrivers(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editDriver = (drvr) => {
  emit("clickEdit", drvr)
}

function sortDrivers(field) {
  if (field === sortField.value) {
    if (sortDirection.value === "desc") {
      sortDirection.value = "asc"
    } else {
      sortDirection.value = "desc"
    }
  } else {
    sortField.value = field
    sortDirection.value = "asc"
  }

  getDrivers()
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="گەڕانی شۆفێر، ناوی یەکەم، ناوی کۆتایی، ئیمەیڵ و..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6">
            <select
              @change="getDrivers(null)"
              v-model="driverStatus"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>دۆخی شۆفێر هەڵبژێرە</option>
              <option value="0">ناچالاک</option>
              <option value="1">چالاک</option>
            </select>
          </b-col>
          <b-col sm="6">
            <select
              @change="getDrivers(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>ژمارەی ڕیزەکان پیشان بدە</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="driverTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="first_name"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('first_name')"
              >
                ناو
              </TableHeaderCell>
              <TableHeaderCell
                field="last_name"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('last_name')"
              >
                ناوی کۆتایی
              </TableHeaderCell>
              <TableHeaderCell
                field="email"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('email')"
              >
                ئیمەیڵ
              </TableHeaderCell>
              <TableHeaderCell
                field="phone_number"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('phone_number')"
              >
                تەلەفۆن
              </TableHeaderCell>
              <TableHeaderCell
                field="national_code"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('national_code')"
              >
                کۆدی نیشتمانی
              </TableHeaderCell>
              <TableHeaderCell
                field="created_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('created_at')"
              >
                بەرواری ناو تۆمارکردن
              </TableHeaderCell>

              <TableHeaderCell
                field="status"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('status')"
              >
                دۆخ
              </TableHeaderCell>
              <TableHeaderCell
                field="status"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortDrivers('is_active')"
              >
                مۆڵەتی ئیش
              </TableHeaderCell>
              <th class="sort" data-sort="action">ئۆپەراسیۆنەکە</th>
            </tr>
          </thead>
          <tbody class="list" v-if="drivers.loading || !drivers.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="drivers.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ شۆفێرێک نییە!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(driver, index) of drivers.data" :key="index">
              <td class="driver_name">{{ driver.first_name }}</td>
              <td class="driver_last_name">{{ driver.last_name }}</td>
              <td class="email">{{ driver.email }}</td>
              <td class="phone text-start" dir="ltr">
                {{ digitsEnToAr(driver.phone_number) }}
              </td>
              <td class="dnational_codeate">{{ digitsEnToAr(driver.driver_info.national_code) }}</td>

              <td class="date">{{ digitsEnToAr(driver.created_at) }}</td>

              <td class="status">
                <span
                  class="badge"
                  :class="{
                    'bg-success-subtle text-success': driver.status == 1,
                    'bg-danger-subtle text-danger': driver.status == 0,
                  }"
                  >{{ driver.status ? "چالاک" : "ناچالاک" }}</span
                >
              </td>
              <td class="is-active">
                <span
                  class="badge"
                  :class="{
                    'bg-success-subtle text-success':
                      driver.driver_info.is_active == 1,
                    'bg-danger-subtle text-danger':
                      driver.driver_info.is_active == 0,
                  }"
                  >{{ driver.driver_info.is_active ? "بەڵێ" : "نەخێر" }}</span
                >
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="بینینی وردەکارییەکانی شۆفێر"
                  >
                    <RouterLink
                      :to="{
                        name: 'driver.details',
                        params: { driverId: driver.id },
                      }"
                      class="text-primary d-inline-block"
                      target="_blank"
                      ><i class="ri-eye-fill fs-16"></i>
                    </RouterLink>
                  </li>

                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="دەستکاری شۆفێر"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editDriver(driver)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>

                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="فەرمانەکان"
                  >
                    <RouterLink
                      :to="{
                        name: 'driver.orders',
                        params: { driverId: driver.id },
                      }"
                      class="text-primary d-inline-block"
                      ><i class="ri-shopping-bag-3-fill fs-16"></i>
                    </RouterLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="drivers.total > drivers.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of drivers.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<style>
.vpd-icon-btn {
  margin-bottom: 0 !important;
  padding: 0.5rem 0.9rem !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.5rem 0.9rem !important;
}
</style>
