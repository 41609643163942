<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import NotificationModal from "./NotificationModal.vue"
import NotificationsTable from "./NotificationsTable.vue"

const title = "بەڕێوەبردنی ئاگادارکردنەوەکان"
const pageTitle = "داشبۆرد"

const DEFAULT_NOTIFICATION = {
  id: "",
  title: "",
  message: "",
}

const notificationModel = ref({ ...DEFAULT_NOTIFICATION })
const showNotificationModal = ref(false)

const showAddNewModal = () => {
  showNotificationModal.value = true
}

const onModalClose = () => {
  notificationModel.value = { ...DEFAULT_NOTIFICATION }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="notificationList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیستی ئاگادارییەکان</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i
                    >ئاگادارکردنەوە بنێرن
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- notifications table  -->

          <NotificationsTable />

          <!-- add and edit notification modal  -->

          <NotificationModal
            v-model="showNotificationModal"
            :notification="notificationModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
