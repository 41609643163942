import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"


export default function usenotifications(props, emit, notificationData) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const perPage = ref(10)
    const sortField = ref("created_at")
    const sortDirection = ref("desc")
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getNotifications = (url = null) => {
        store.dispatch("getNotifications", {
            url,
            search: search.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }


    const onSubmit = () => {
        loading.value = true
        store
            .dispatch("createNotification", notificationData.value)
            .then((response) => {
                loading.value = false
                if (response.status === 201) {
                    toast.success("ئاگادارکردنەوە بە سەرکەوتوویی بنێرە!", {
                        timeout: 3000,
                    })
                    store.dispatch("getNotifications")
                    closeModal()
                }
            })
            .catch((err) => {
                loading.value = false
                errors.value = err.response.data.errors
            })
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }


    return {
        show,
        search,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getNotifications,
        onSubmit,
        closeModal,
    }
}