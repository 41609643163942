<script setup>
import { computed, onMounted, ref } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useMessages from "../../use/useMessages"
import useDebouncedFunction from "../../use/useDebouncedFunction"

const messages = computed(() => store.state.messages)

const { search, unreadMessages, perPage, getMessages } = useMessages()
const { handleInput } = useDebouncedFunction(getMessages)

onMounted(() => {
  getMessages()
})

const shortenCommentText = (text) => {
  if (text.length <= 50) {
    return text
  } else {
    return text.slice(0, 50) + "..."
  }
}

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getMessages(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="گەڕانی ناوی بەکارهێنەر و..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6">
            <select
              @change="getMessages(null)"
              v-model="unreadMessages"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>دۆخی پەیامەکان هەڵبژێرە</option>
              <option value="unread-messages">نەخوێنراوەتەوە</option>
            </select>
          </b-col>

          <b-col sm="6">
            <select
              @change="getMessages(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>ژمارەی ڕیزەکان پیشان بدە</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="messageTable">
          <thead class="table-light text-muted">
            <tr>
              <th class="sort" data-sort="user">ناوی بەکارهێنەر</th>
              <th class="sort" data-sort="phone">ژمارەی تەلەفۆن</th>
              <th class="sort" data-sort="message">پەیام</th>
              <th class="sort" data-sort="action">ئۆپەراسیۆنەکە</th>
            </tr>
          </thead>
          <tbody class="list" v-if="messages.loading || !messages.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="messages.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ پەیامێک نییە!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr
              v-for="(message, index) of messages.data"
              :key="index"
              :class="{ 'text-muted': message.unread_messages_count <= 0 }"
            >
              <td class="user">
                <RouterLink
                  :to="{
                    name: 'users.messages',
                    params: { userId: message.user_id },
                  }"
                  :class="
                    message.unread_messages_count > 0
                      ? 'link-primary'
                      : 'link-info'
                  "
                >
                  {{ message.user_fullname }}
                </RouterLink>
              </td>
              <td class="phone" dir="ltr">{{ message.phone_number }}</td>
              <td class="message">
                {{ shortenCommentText(message.latest_message) }}
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="پەیامەکانی پشتیوانی"
                    v-if="message.unread_messages_count > 0"
                  >
                    <RouterLink
                      :to="{
                        name: 'users.messages',
                        params: { userId: message.user_id },
                      }"
                      class="btn btn-danger btn-sm"
                    >
                      پەیام
                      <b-badge variant="dark" class="ms-1">{{
                        message.unread_messages_count
                      }}</b-badge>
                    </RouterLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="messages.total > messages.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of messages.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
