<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import UserModal from "./UserModal.vue"
import UserEditModal from "./UserEditModal.vue"
import UsersTable from "./UsersTable.vue"

const title = "بەڕێوەبردنی بەکارهێنەران"
const pageTitle = "داشبۆرد"

const DEFAULT_USER = {
  id: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  status: "",
}

const userModel = ref({ ...DEFAULT_USER })
const showUserModal = ref(false)
const showUserEditModal = ref(false)

const showAddNewModal = () => {
  showUserModal.value = true
}

const showEditModal = () => {
  showUserEditModal.value = true
}

const editUser = (usr) => {
  userModel.value = usr
  showEditModal()
}

const onModalClose = () => {
  userModel.value = { ...DEFAULT_USER }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="userList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیستی بەکارهێنەران</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> بەکارهێنەری
                    نوێ زیاد بکە
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- users table  -->

          <UsersTable @clickEdit="editUser" />

          <!-- add and edit user modal  -->

          <UserModal
            v-model="showUserModal"
            :user="userModel"
            @close="onModalClose"
          />

          <UserEditModal
            v-model="showUserEditModal"
            :user="userModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
