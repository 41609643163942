<script setup>
import { onMounted, computed } from "vue"
import router from "../router"
import store from "../state/store"

const toggleHamburgerMenu = () => {
  var windowSize = document.documentElement.clientWidth
  let layoutType = document.documentElement.getAttribute("data-layout")
  let visiblilityType = document.documentElement.getAttribute(
    "data-sidebar-visibility"
  )

  document.documentElement.setAttribute("data-sidebar-visibility", "show")

  if (windowSize > 767)
    document.querySelector(".hamburger-icon").classList.toggle("open")

  //For collapse horizontal menu
  if (document.documentElement.getAttribute("data-layout") === "horizontal") {
    document.body.classList.contains("menu")
      ? document.body.classList.remove("menu")
      : document.body.classList.add("menu")
  }

  //For collapse vertical menu
  if (
    visiblilityType === "show" &&
    (layoutType === "vertical" || layoutType === "semibox")
  ) {
    if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("vertical-sidebar-enable")
      document.documentElement.getAttribute("data-sidebar-size") == "sm"
        ? document.documentElement.setAttribute("data-sidebar-size", "")
        : document.documentElement.setAttribute("data-sidebar-size", "sm")
    } else if (windowSize > 1025) {
      document.body.classList.remove("vertical-sidebar-enable")
      document.documentElement.getAttribute("data-sidebar-size") == "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm")
        : document.documentElement.setAttribute("data-sidebar-size", "lg")
    } else if (windowSize <= 767) {
      document.body.classList.add("vertical-sidebar-enable")
      document.documentElement.setAttribute("data-sidebar-size", "lg")
    }
  }

  //Two column menu
  if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
    document.body.classList.contains("twocolumn-panel")
      ? document.body.classList.remove("twocolumn-panel")
      : document.body.classList.add("twocolumn-panel")
  }
}

const toggleDarkMode = () => {
  if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
    document.documentElement.setAttribute("data-layout-mode", "light")
  } else {
    document.documentElement.setAttribute("data-layout-mode", "dark")
  }
}

const initTopbarComponents = () => {
  // notification messages
  if (document.getElementsByClassName("notification-check")) {
    Array.from(document.querySelectorAll(".notification-check input")).forEach(
      function (element) {
        element.addEventListener("click", function (el) {
          el.target.closest(".notification-item").classList.toggle("active")
        })
      }
    )
  }
}

const handleScroll = () => {
  var pageTopbar = document.getElementById("page-topbar")
  if (pageTopbar) {
    document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50
      ? pageTopbar.classList.add("topbar-shadow")
      : pageTopbar.classList.remove("topbar-shadow")
  }
}

const currentUser = computed(() => store.state.user.data)

const logout = () => {
  store.dispatch("logout").then(() => {
    router.push({ name: "login" })
  })
}

onMounted(() => {
  document.addEventListener("scroll", handleScroll)

  if (document.getElementById("topnav-hamburger-icon")) {
    document
      .getElementById("topnav-hamburger-icon")
      .addEventListener("click", toggleHamburgerMenu)
  }

  initTopbarComponents()
})
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="40" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="40" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="40" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="40" />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>

        <div class="d-flex align-items-center">
          <!-- برای فعال کردن دکمه حالت دارک مود و لایت مود پنل کلاس d-sm-flex را به تگ زیر
    اضافه کن  -->
          <div class="ms-1 header-item d-none">
            <b-button
              type="button"
              variant="ghost-secondary"
              class="btn-icon btn-topbar rounded-circle light-dark-mode shadow-none"
              @click="toggleDarkMode"
            >
              <i class="bx bx-moon fs-22"></i>
            </b-button>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button
              type="button"
              class="btn shadow-none"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <img
                  class="rounded-circle header-profile-user"
                  src="@/assets/images/users/avatar-1.jpg"
                  alt="Header Avatar"
                />
                <span class="text-start ms-xl-2">
                  <span
                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                    v-if="currentUser.first_name !== undefined"
                  >
                    {{ currentUser.first_name + " " + currentUser.last_name }}
                  </span>
                  <span
                    class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                    v-if="store.getters.isAdmin"
                    >بەڕێوەبەری سیستەم</span
                  >
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header" v-if="currentUser.first_name !== undefined">
                {{
                  currentUser.ur === "seller"
                    ? currentUser.first_name
                    : currentUser.first_name + " " + currentUser.last_name
                }}
                بەخێربێیت
              </h6>
              <router-link
                v-if="store.getters.isAdmin || store.getters.isOperator"
                class="dropdown-item"
                to="/dashboard/profile"
                ><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">پرۆفایل</span>
              </router-link>
              <router-link
                v-else-if="store.getters.isSeller"
                class="dropdown-item"
                to="/store/dashboard/profile"
                ><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">پرۆفایل</span>
              </router-link>
              <router-link
                v-if="store.getters.isAdmin || store.getters.isOperator"
                class="dropdown-item"
                to="/dashboard/messages"
              >
                <i
                  class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"
                ></i>
                 <span class="align-middle"> پەیامەکان </span>
              </router-link>

              <div class="dropdown-divider"></div>
              <b-button class="dropdown-item" @click="logout">
                <i
                  class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">دەرچوون</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
