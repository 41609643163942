<script>
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/mousewheel";

import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/main.vue";


export default {
  components: {

    Layout,
  },

  methods: {
    rightcolumn() {
      if (document.querySelector('.layout-rightside-col').classList.contains('d-block')) {
        document.querySelector('.layout-rightside-col').classList.remove('d-block');
        document.querySelector('.layout-rightside-col').classList.add('d-none');
      } else {
        document.querySelector('.layout-rightside-col').classList.remove('d-none');
        document.querySelector('.layout-rightside-col').classList.add('d-block');
      }
    },

    resizerightcolumn() {
      const element = document.querySelector('.layout-rightside-col');

      if (element) {
        if (window.outerWidth < 1699) {
          element.classList.remove("d-block");
          element.classList.add("d-none");
        } else {
          element.classList.add("d-block");
          element.classList.remove("d-none");
        }
      }

      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        element.classList.remove("d-block");
        element.classList.add("d-none");
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizerightcolumn);
  }

};
</script>

<template>
  <Layout>
    <BRow>
      <BCol>
        <div class="h-100">
          <BRow class="mb-3 pb-1">
            <BCol cols="12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">پانێڵی بەڕێوەبردن</h4>
                  
                </div>
                <div class="mt-3 mt-lg-0">
                  <for action="javascript:void(0);">
                    <BRow class="g-3 mb-0 align-items-center">
                      <BCol sm="auto">
                        <div class="input-group">
                          
                        </div>
                      </BCol>
                     
                    </BRow>
                  </for>
                </div>
              </div>
            </BCol>
          </BRow>
        </div>
      </BCol>

    </BRow>
  </Layout>
</template>