import dashboard from '../views/dashboard/admin/index.vue'
import users from '../views/users/index.vue'
import UserVehiclesTable from '../views/users/UserVehiclesTable.vue'
import drivers from '../views/drivers/index.vue'
import DriverDetails from '../views/drivers/driver-details.vue'
import DriverOrders from '../views/drivers/DriverOrders.vue'
import DriverOrderDetails from '../views/drivers/DriverOrderDetails.vue'
import fuelTypes from '../views/fuel/index.vue'
import userMessages from '../views/users/userMessages.vue'
import messages from '../views/messages/index.vue'
import notifications from '../views/notifications/index.vue'
import profile from '../views/settings/Profile.vue'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: {
      title: "پانێڵی بەڕێوەبردن",
      authRequired: true,
      isAdmin: true,
      isOperator: true
    }
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: users,
    meta: {
      title: "بەڕێوەبردنی بەکارهێنەران",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/dashboard/user/vehicles/:userId',
    name: 'user.vehicles',
    component: UserVehiclesTable,
    meta: {
      title: "لیستی ئۆتۆمبێلی بەکارهێنەر",
      authRequired: true,
      isAdmin: true,

    }
  },

  {
    path: '/dashboard/drivers',
    name: 'drivers',
    component: drivers,
    meta: {
      title: "بەڕێوەبردنی شۆفێران",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/dashboard/driver/details/:driverId',
    name: 'driver.details',
    component: DriverDetails,
    meta: {
      title: "وردەکارییەکانی شۆفێر",
      authRequired: true,
      isAdmin: true,

    }
  },

  {
    path: '/dashboard/driver/orders/:driverId',
    name: 'driver.orders',
    component: DriverOrders,
    props: true,
    meta: {
      title: "ئۆردەرەکان شۆفێر",
      authRequired: true,
      isAdmin: true,
      isOperator: true
    }
  },

  {
    path: '/dashboard/driver/order/details/:id',
    name: 'orders.details',
    component: DriverOrderDetails,
    meta: {
      title: "وردەکارییەکانی ئۆردەرەکه",
      authRequired: true,
      isAdmin: true,
      props: true,
    }
  },

  {
    path: '/dashboard/fuel/types',
    name: 'fuel.types',
    component: fuelTypes,
    meta: {
      title: "جۆرەکانی سووتەمەنی",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/dashboard/users/messages/:userId',
    name: 'users.messages',
    component: userMessages,
    meta: {
      title: "پەیامەکانی بەکارهێنەر",
      authRequired: true,
      isAdmin: true,
      props: true,
    }
  },
  {
    path: '/dashboard/messages',
    name: 'messages',
    component: messages,
    meta: {
      title: "لیستی پەیامەکان",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/dashboard/notifications',
    name: 'notifications',
    component: notifications,
    meta: {
      title: "ئاگادارکردنەوەکان",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/profile',
    name: 'profile',
    component: profile,
    meta: {
      title: "پرۆفایل",
      authRequired: true,
      isAdmin: true,
    }
  },












  {
    path: '/login',
    name: 'login',
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "لاپەڕەی چوونەژوورەوە",
      guestRequired: true
    }
  },
];