import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"


export default function useDrivers(props, emit, driverData) {

    // Get toast interface
    const toast = useToast()

    const driverStatus = ref("")
    const search = ref("")
    const createdAtDate = ref("")
    const perPage = ref(10)
    const sortField = ref("created_at")
    const sortDirection = ref("desc")
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getDrivers = (url = null) => {
        store.dispatch("getDrivers", {
            url,
            search: search.value,
            status: driverStatus.value,
            created_at: createdAtDate.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (driverData.value.id) {
            store.dispatch('updateDriver', driverData.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("شۆفێر بە سەرکەوتوویی نوێکرایەوە!", {
                            timeout: 3000,
                        })
                        store.dispatch("getDrivers")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createDriver", driverData.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        console.log('Yes');
                        toast.success("شۆفێری خوازراو بە سەرکەوتوویی زیادکرا!", {
                            timeout: 3000,
                        })
                        store.dispatch("getDrivers")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    return {
        show,
        driverStatus,
        search,
        createdAtDate,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getDrivers,
        onSubmit,
        closeModal,
    }
}