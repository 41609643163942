<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"
import { digitsEnToAr } from "@persian-tools/persian-tools"

import store from "../../state/store"

const fuelTypes = computed(() => store.state.fuelTypes)

import useFuelTypes from "../../use/useFuelTypes"
import useDebouncedFunction from "../../use/useDebouncedFunction"

import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const {
  fuelTypeStatus,
  search,
  perPage,
  sortField,
  sortDirection,
  getFuelTypes,
} = useFuelTypes()
const { handleInput } = useDebouncedFunction(getFuelTypes)

const emit = defineEmits(["clickEdit"])

onMounted(() => {
  getFuelTypes()
})

const formattedPrices = (value) => {
  return new Intl.NumberFormat("ar-IQ").format(value)
}

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getFuelTypes(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editFuelType = (fultype) => {
  emit("clickEdit", fultype)
}

function sortFuelTypes(field) {
  if (field === sortField.value) {
    if (sortDirection.value === "desc") {
      sortDirection.value = "asc"
    } else {
      sortDirection.value = "desc"
    }
  } else {
    sortField.value = field
    sortDirection.value = "asc"
  }

  getFuelTypes()
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="ناوی گەڕان و وەسف و..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6">
            <select
              @change="getFuelTypes(null)"
              v-model="fuelTypeStatus"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>دۆخی سووتەمەنی هەڵبژێرە</option>
              <option value="0">ناچالاک</option>
              <option value="1">چالاک</option>
            </select>
          </b-col>
          <b-col sm="6">
            <select
              @change="getFuelTypes(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>ژمارەی ڕیزەکان پیشان بدە</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="fuelTypeTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="name"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortFuelTypes('name')"
              >
                ناو
              </TableHeaderCell>
              <TableHeaderCell
                field="price_per_liter"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortFuelTypes('price_per_liter')"
              >
                نرخی هەر لیترێک
              </TableHeaderCell>
              <TableHeaderCell
                field="description"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortFuelTypes('description')"
              >
                وەسف
              </TableHeaderCell>

              <TableHeaderCell
                field="created_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortFuelTypes('created_at')"
              >
                بەرواری ناو تۆمارکردن
              </TableHeaderCell>

              <TableHeaderCell
                field="status"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortFuelTypes('status')"
              >
                دۆخ
              </TableHeaderCell>
              <th class="sort" data-sort="action">ئۆپەراسیۆنەکە</th>
            </tr>
          </thead>
          <tbody
            class="list"
            v-if="fuelTypes.loading || !fuelTypes.data.length"
          >
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="fuelTypes.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">سووتەمەنی نییە!!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(fuelType, index) of fuelTypes.data" :key="index">
              <td class="fuelType_name">{{ fuelType.name }}</td>
              <td class="fuelType_price_per_liter">
                {{ formattedPrices(fuelType.price_per_liter) }}
              </td>
              <td class="description">{{ fuelType.description }}</td>
              <td class="date">{{ digitsEnToAr(fuelType.created_at) }}</td>
              <td class="status">
                <span
                  class="badge"
                  :class="{
                    'bg-success-subtle text-success': fuelType.status == 1,
                    'bg-danger-subtle text-danger': fuelType.status == 0,
                  }"
                  >{{ fuelType.status ? "چالاک" : "ناچالاک" }}</span
                >
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="دەستکاریکردنی سووتەمەنی"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editFuelType(fuelType)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="fuelTypes.total > fuelTypes.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of fuelTypes.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<style>
.vpd-icon-btn {
  margin-bottom: 0 !important;
  padding: 0.5rem 0.9rem !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.5rem 0.9rem !important;
}
</style>
