<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useNotifications from "../../use/useNotifications"

const props = defineProps({
  notification: {
    required: true,
    type: Object,
  },
})

const notificationData = ref({
  id: props.notification.id,
  title: props.notification.title,
  message: props.notification.message,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useNotifications(
  props,
  emit,
  notificationData
)

onUpdated(() => {
  notificationData.value = {
    id: props.notification.id,
    title: props.notification.title,
    message: props.notification.message,
  }
})
</script>

<template>
  <!-- add notification modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="ئاگادارکردنەوە بنێرن"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="sendform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="title" class="form-label"> تایتەڵ</label>
            <input
              type="text"
              id="title"
              class="form-control"
              placeholder="تایتەڵ ئاگادارکردنەوە"
              v-model="notificationData.title"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.title">
                <span>{{ errors.title[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">دەق</label>
            <textarea
              id="message"
              class="form-control"
              placeholder="دەق ئاگادارکردنەوە"
              v-model="notificationData.message"
            >
            </textarea>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.message">
                <span>{{ errors.message[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              داخستن
            </b-button>
            <b-button type="submit" variant="success"
              >ئاگادارکردنەوە بنێرن</b-button
            >
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
