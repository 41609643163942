<script setup>
import { ref, reactive, onMounted } from "vue"
import { useToast } from "vue-toastification"
import router from "../../router"

import Layout from "../../layouts/main.vue"

import store from "../../state/store"

// Get toast interface
const toast = useToast()

const user = ref([])

const passwordData = reactive({
  oldpassword: "",
  newpassword: "",
  newpassword_confirmation: "",
})

const confirmpasswordError = ref(null)
const oldPasswordError = ref(null)
const errors = ref({})
const loading = ref(false)

onMounted(async () => {
  const data = await store.dispatch("getCurrentUser")
  user.value = {
    ...data.user,
  }
})

const onSubmit = () => {
  loading.value = true
  store
    .dispatch("updateProfileInfo", {
      user,
    })
    .then((response) => {
      loading.value = false
      if (response.status === 200) {
        toast.success("زانیارییەکانتان بە سەرکەوتوویی نوێ کراوەتەوە!", {
          timeout: 3000,
        })
      }
    })
    .catch((err) => {
      loading.value = false
      errors.value = err.response.data.errors
    })
}

const changePassword = async () => {
  loading.value = true
  if (passwordData.newpassword !== passwordData.newpassword_confirmation) {
    confirmpasswordError.value =
      "پێویستە وشەی نهێنی نوێ و پشتڕاستکردنەوەی وەک یەک بێت"
    return
  }
  try {
    const response = await store.dispatch("changePassword", passwordData)

    if (response.status === 200) {
      toast.success("وشەی نهێنی بە سەرکەوتوویی گۆڕدرا!", {
        timeout: 3000,
      })

      await store.dispatch("logout")
      router.push({ name: "login" })
    }
  } catch (err) {
    oldPasswordError.value = err.response.data.Error
    loading.value = false
    errors.value = err.response.data.errors
  }
}
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img"></div>
    </div>

    <b-row>
      <b-col xxl="3">
        <b-card no-body class="mt-n5">
          <b-card-body class="p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  src="@/assets/images/users/avatar-1.jpg"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                  alt="user-profile-image"
                />
              </div>
              <h5 class="fs-16 mb-1">
                {{ user.name ? user.name : "" }}
                {{ user.surname ? user.surname : "" }}
              </h5>
              <p class="text-muted mb-0" v-if="user.ur && user.ur === 'admin'">
                بەڕێوەبەری سیستەم
              </p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xxl="9">
        <b-overlay
          variant="white"
          opacity="0.75"
          blur="2px"
          :show="loading"
          spinner-variant="success"
          rounded="sm"
        >
          <b-card no-body class="mt-xxl-n5">
            <b-card-header>
              <ul
                class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <b-link
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#personalDetails"
                    role="tab"
                  >
                    <i class="fas fa-home"></i>
                    زانیاری کەسی
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#changePassword"
                    role="tab"
                  >
                    <i class="far fa-user"></i>
                    گۆڕینی ژمارەی نهێنی
                  </b-link>
                </li>
              </ul>
            </b-card-header>
            <b-card-body class="p-4">
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="personalDetails"
                  role="tabpanel"
                >
                  <form @submit.prevent="onSubmit">
                    <b-row>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="firstnameInput" class="form-label"
                            >ناو</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="firstnameInput"
                            placeholder="ناوی خۆت تۆمار بکە"
                            v-model="user.name"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.first_name"
                            >
                              <span>{{ errors.first_name[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="lastnameInput" class="form-label"
                            >ناوی کۆتایی</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="lastnameInput"
                            placeholder="ناوی کۆتایی خۆت بنووسە"
                            v-model="user.surname"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.last_name"
                            >
                              <span>{{ errors.last_name[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="phonenumberInput" class="form-label"
                            >ژمارەی تەلەفۆن</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="phonenumberInput"
                            placeholder="ژمارەی پەیوەندیکردنت داخڵ بکە"
                            v-model="user.phone"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.phone_number"
                            >
                              <span>{{ errors.phone_number[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="emailInput" class="form-label"
                            >ئیمەیڵ</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="emailInput"
                            placeholder="ئیمێلەکەت داخڵ بکە"
                            v-model="user.email"
                          />
                          <div v-if="errors">
                            <div class="invalid-feedback" v-if="errors.email">
                              <span>{{ errors.email[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col lg="12">
                        <div class="hstack gap-2 justify-content-end">
                          <b-button
                            type="submit"
                            variant="primary"
                            @click="updatedata"
                          >
                            بژارکردن
                          </b-button>
                          <b-button type="button" variant="soft-success">
                            هەڵوەشاندنەوە
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </div>
                <div class="tab-pane" id="changePassword" role="tabpanel">
                  <form @submit.prevent="changePassword">
                    <b-alert
                      v-model="confirmpasswordError"
                      variant="danger"
                      class="mt-3"
                      dismissible
                      >{{ confirmpasswordError }}</b-alert
                    >
                    <b-row class="g-2">
                      <b-col lg="4">
                        <div>
                          <label for="oldpasswordInput" class="form-label"
                            >وشەی نهێنی پێشوو*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="oldpasswordInput"
                            placeholder="وشەی نهێنی پێشوو"
                            v-model="passwordData.oldpassword"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.oldpassword"
                            >
                              <span>{{ errors.oldpassword[0] }}</span>
                            </div>
                          </div>
                          <div class="invalid-feedback" v-if="oldPasswordError">
                            <span>{{ oldPasswordError }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <div>
                          <label for="newpasswordInput" class="form-label"
                            >تێپەڕەوشەی نوێ*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="newpasswordInput"
                            placeholder="تێپەڕەوشەی نوێ"
                            v-model="passwordData.newpassword"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.newpassword"
                            >
                              <span>{{ errors.newpassword[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <div>
                          <label for="confirmpasswordInput" class="form-label"
                            >ووشه‌ی تیپه‌ر دووباره‌ بكه‌وه‌*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="confirmpasswordInput"
                            placeholder="ووشه‌ی تیپه‌ر دووباره‌ بكه‌وه‌"
                            v-model="passwordData.newpassword_confirmation"
                          />
                        </div>
                      </b-col>
                      <b-col lg="12">
                        <div class="text-end">
                          <b-button type="submit" variant="success">
                            گۆڕینی ژمارەی نهێنی
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.profile-setting-img {
  height: 125px !important;
}
</style>
