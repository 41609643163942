import axiosClient from "../../axios";

export async function getCurrentUser({ commit }, data) {
    return await axiosClient.get('/user/info', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            return data;
        })
}

export async function login({ commit }, data) {
    return await axiosClient.post('/login', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            commit('setToken', data.token)
            return data;
        })
}

export async function logout({ commit }) {
    return await axiosClient.post('/logout')
        .then((response) => {
            commit('setToken', null)
            commit('setUser', null);

            return response
        })
}