<script setup>
import simplebar from "simplebar-vue"
import { ref, onMounted, nextTick } from "vue"
import { useRoute } from "vue-router"
import { SearchIcon } from "@zhuowenli/vue-feather-icons"
import Layout from "../../layouts/main.vue"

import store from "../../state/store"

const route = useRoute()
const userId = route.params.userId

const messagesData = ref([])
const replyMessage = ref({
  user_id: userId,
  message: "",
})
const loading = ref(false)
const errors = ref({})
const userInfo = ref()

const scrollToBottom = (id) => {
  const simpleBar = document
    .getElementById(id)
    .querySelector("#chat-conversation .simplebar-content-wrapper")
    ? document
        .getElementById(id)
        .querySelector("#chat-conversation .simplebar-content-wrapper")
    : ""

  const offsetHeight = document.getElementsByClassName(
    "chat-conversation-list"
  )[0]
    ? document
        .getElementById(id)
        .getElementsByClassName("chat-conversation-list")[0].scrollHeight -
      window.innerHeight +
      600
    : 0

  if (offsetHeight) {
    simpleBar.scrollTo({
      top: offsetHeight,
      behavior: "smooth",
    })
  }
}

const fetchData = async () => {
  try {
    const { data } = await store.dispatch("getUserMessages", userId)
    messagesData.value = {
      ...data.data,
    }

    const userData = await store.dispatch("getUser", userId)
    userInfo.value = {
      ...userData.data.data,
    }
  } catch (error) {
    console.error("هەڵە لە وەرگرتنی زانیارییەکان:", error)
  }
}

onMounted(async () => {
  await fetchData()

  const currentChatId = "users-chat"

  await nextTick()
  scrollToBottom(currentChatId)
})

const onSubmit = () => {
  store
    .dispatch("replyToMessage", replyMessage.value)
    .then((response) => {
      loading.value = false
      if (response.status === 201) {
        window.location.reload()

        replyMessage.value.message = ""
        errors.value = null
      }
    })
    .catch((err) => {
      loading.value = false
      errors.value = err.response.data.errors
    })
}
</script>

<template>
  <Layout>
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <div class="chat-wrapper d-flex gap-1 mx-n4 mt-n4 p-1">
        <div class="user-chat w-100 overflow-hidden">
          <div class="chat-content d-flex">
            <div class="w-100 overflow-hidden position-relative">
              <div class="position-relative">
                <div class="p-3 user-chat-topbar">
                  <b-row class="align-items-center">
                    <b-col sm="4" cols="8">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1 overflow-hidden">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1 overflow-hidden">
                              <h5 class="text-truncate mb-0 fs-16">
                                <b-link
                                  class="text-reset username"
                                  data-bs-toggle="offcanvas"
                                  href="#userProfileCanvasExample"
                                  aria-controls="userProfileCanvasExample"
                                  >{{
                                    userInfo
                                      ? userInfo.first_name +
                                        " " +
                                        userInfo.last_name
                                      : ""
                                  }}
                                </b-link>
                              </h5>
                              <p
                                class="text-truncate text-muted fs-14 mb-0 userStatus"
                              >
                                <small>بەکارهێنەر</small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="8" cols="4">
                      <ul class="list-inline user-chat-nav text-end mb-0">
                        <li class="list-inline-item m-0">
                          <div class="dropdown">
                            <button
                              class="btn btn-ghost-secondary btn-icon shadow-none"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <search-icon class="icon-sm"></search-icon>
                            </button>
                            <div
                              class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg"
                            >
                              <div class="p-2">
                                <div class="search-box">
                                  <input
                                    type="text"
                                    class="form-control bg-light border-light"
                                    placeholder="گەڕان..."
                                    onkeyup="searchMessages()"
                                    id="searchMessage"
                                  />
                                  <i class="ri-search-2-line search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </div>

                <div class="position-relative" id="users-chat">
                  <simplebar
                    class="chat-conversation p-3 p-lg-4"
                    id="chat-conversation"
                    data-simplebar
                    ref="current"
                  >
                    <ul class="list-unstyled chat-conversation-list">
                      <li
                        v-for="data of messagesData"
                        :key="data.id"
                        :class="{
                          right: `${data.senderType}` === 'support',
                          left: `${data.senderType}` === 'user',
                        }"
                        class="chat-list"
                      >
                        <div class="conversation-list">
                          <div class="user-chat-content">
                            <div class="ctext-wrap">
                              <div class="ctext-wrap-content">
                                <p class="mb-0 ctext-content">
                                  {{ data.messageText }}
                                </p>
                                <p
                                  v-if="data.support"
                                  class="fw-bold mt-3 mb-0 text-warning"
                                >
                                  <small>
                                    {{ data.support.first_name }}
                                    {{ data.support.last_name }}</small
                                  >
                                </p>
                              </div>

                              <div class="conversation-name mx-3">
                                <small class="text-muted time">{{
                                  data.datetime
                                }}</small>
                                <span
                                  v-if="data.isRead"
                                  class="text-success check-message-icon"
                                  ><i
                                    class="ri-check-double-line align-bottom"
                                  ></i
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </simplebar>
                </div>

                <div class="chat-input-section p-3 p-lg-4">
                  <form @submit.prevent="onSubmit">
                    <b-row class="g-0 align-items-center">
                      <b-col>
                        <div class="chat-input-feedback">
                          تکایە نامەیەک داخڵ بکە
                        </div>

                        <input
                          type="text"
                          v-model="replyMessage.message"
                          class="form-control chat-input bg-light border-light"
                          placeholder="نامەکەت بنووسە..."
                          :class="{
                            'is-invalid':
                              errors && errors.message ? true : false,
                          }"
                        />
                        <div v-if="errors" class="invalid-feedback">
                          <span v-if="errors.message">{{
                            errors.message[0]
                          }}</span>
                        </div>
                      </b-col>
                      <b-col cols="auto">
                        <div class="chat-input-links ms-2">
                          <div class="links-list-item">
                            <b-button
                              variant="primary"
                              type="submit"
                              class="chat-send shadow"
                            >
                              <i class="ri-send-plane-2-fill align-bottom"></i>
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style>
@media (max-width: 991.98px) {
  .user-chat {
    position: relative !important;
    visibility: visible !important;
    transform: translateX(0) !important;
  }
}

.invalid-feedback {
  display: block !important;
}

.ri-send-plane-2-fill {
  transform: rotate(180deg);
}
</style>
