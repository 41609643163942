<script setup>
import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"

const title = "وردەکارییەکانی شۆفێر"
const pageTitle = "بەڕێوەبردنی شۆفێران"

const route = useRoute()
const id = route.params.driverId

const driverDetails = ref([])

onMounted(async () => {
  const { data } = await store.dispatch("getDriver", id)
  driverDetails.value = {
    ...data.data,
  }
})
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <BRow>
      <BCol xxl="5">
        <BCard no-body>
          <BCardBody class="p-4">
            <div>
              <div class="flex-shrink-0 avatar-md mx-auto">
                <div class="avatar-title bg-light rounded">
                  <img
                    src="@/assets/images/companies/img-2.png"
                    alt=""
                    height="50"
                  />
                </div>
              </div>
              <div class="mt-4 text-center">
                <h5 class="mb-1">
                  {{
                    driverDetails
                      ? driverDetails.first_name + " " + driverDetails.last_name
                      : ""
                  }}
                </h5>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-borderless">
                  <tbody>
                    <tr>
                      <th><span class="fw-medium">ئیمەیڵ</span></th>
                      <td>{{ driverDetails ? driverDetails.email : "" }}</td>
                    </tr>
                    <tr>
                      <th><span class="fw-medium">ژمارەی تەلەفۆن</span></th>
                      <td>
                        {{ driverDetails ? driverDetails.phone_number : "" }}
                      </td>
                    </tr>
                    <tr>
                      <th><span class="fw-medium">کۆدی نیشتمانی</span></th>
                      <td>
                        {{
                          driverDetails.driver_info
                            ? driverDetails.driver_info.national_code
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="fw-medium">ژمارەی مۆڵەتی شۆفێری</span>
                      </th>
                      <td>
                        {{
                          driverDetails.driver_info
                            ? driverDetails.driver_info.driving_license_number
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="fw-medium">ژمارەی پلاکی ئۆتۆمبێل</span>
                      </th>
                      <td>
                        {{
                          driverDetails.driver_info
                            ? driverDetails.driver_info.vehicle_plate_number
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="fw-medium">کۆدی gps ی ئۆتۆمبێل</span>
                      </th>
                      <td>
                        {{
                          driverDetails.driver_info
                            ? driverDetails.driver_info.vehicle_gps_code
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="fw-medium">ناونیشان</span>
                      </th>
                      <td>
                        {{
                          driverDetails.driver_info
                            ? driverDetails.driver_info.address
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="fw-medium"
                          >بەروار و کاتی ناو تۆمارکردن</span
                        >
                      </th>
                      <td>
                        {{ driverDetails ? driverDetails.created_at : "" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BCardBody>
        </BCard>
      </BCol>

      <BCol xxl="7">
        <BCard no-body>
          <BCardHeader class="border-0 align-items-center d-flex">
            <BCardTitle class="mb-0 flex-grow-1"
              >وێنەی کارتی نیشتمانی و وێنەی مۆڵەتی شۆفێری</BCardTitle
            >
          </BCardHeader>

          <BCardBody class="p-2">
            <div>
              <figure class="figure">
                <b-img
                  :src="
                    driverDetails.driver_info
                      ? driverDetails.driver_info.national_card_photo
                      : ''
                  "
                  class="figure-img img-fluid rounded"
                  alt="وێنەی کارتی نیشتمانی"
                ></b-img>
                <figcaption class="figure-caption">
                  وێنەی کارتی نیشتمانی
                </figcaption>
              </figure>

              <figure class="figure">
                <b-img
                  :src="
                    driverDetails.driver_info
                      ? driverDetails.driver_info.driving_license_photo
                      : ''
                  "
                  class="figure-img img-fluid rounded"
                  alt="وێنەی مۆڵەتی شۆفێری"
                ></b-img>
                <figcaption class="figure-caption">
                  وێنەی مۆڵەتی شۆفێری
                </figcaption>
              </figure>
            </div>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </Layout>
</template>
