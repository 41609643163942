// composables/useCounts.js
import { ref, onMounted } from "vue";
import store from "../state/store";

export function useCounts() {
    const messageCount = ref(0);
    const fetchCounts = async () => {
        try {
            await store.dispatch("getMessageCount").then(({ data }) => {
                messageCount.value = data.messageCount;
            });
        } catch (error) {
            console.error(error);
        }
    };

    onMounted(fetchCounts);

    return {
        messageCount,
    };
}
