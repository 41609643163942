<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useDrivers from "../../use/useDrivers"
import usePersianTools from "../../use/usePersianTools"

const props = defineProps({
  driver: {
    required: true,
    type: Object,
  },
})

const driverData = ref({
  id: props.driver.id,
  first_name: props.driver.first_name,
  last_name: props.driver.last_name,
  phone_number: props.driver.phone_number,
  email: props.driver.email,
  driver_info_id: props.driver.driver_info.driver_info_id,
  national_code: props.driver.driver_info.national_code,
  driving_license_number: props.driver.driver_info.driving_license_number,
  address: props.driver.driver_info.address,
  vehicle_plate_number: props.driver.driver_info.vehicle_plate_number,
  vehicle_gps_code: props.driver.driver_info.vehicle_gps_code,
  national_card_photo: props.driver.driver_info.national_card_photo,
  driving_license_photo: props.driver.driver_info.driving_license_photo,
  is_active: props.driver.driver_info.is_active,
  status: props.driver.status,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useDrivers(
  props,
  emit,
  driverData
)

const { convertPersianToEnglish } = usePersianTools()

onUpdated(() => {
  driverData.value = {
    id: props.driver.id,
    first_name: props.driver.first_name,
    last_name: props.driver.last_name,
    phone_number: props.driver.phone_number,
    email: props.driver.email,
    driver_info_id: props.driver.driver_info.driver_info_id,
    national_code: props.driver.driver_info.national_code,
    driving_license_number: props.driver.driver_info.driving_license_number,
    address: props.driver.driver_info.address,
    vehicle_plate_number: props.driver.driver_info.vehicle_plate_number,
    vehicle_gps_code: props.driver.driver_info.vehicle_gps_code,
    national_card_photo: props.driver.driver_info.national_card_photo,
    driving_license_photo: props.driver.driver_info.driving_license_photo,
    is_active: props.driver.driver_info.is_active,
    status: props.driver.status,
  }
})

const onNationalCardFileChange = (event) => {
  driverData.value.national_card_photo = event.target.files[0]
}

const onDrivingLicenseFileChange = (event) => {
  driverData.value.driving_license_photo = event.target.files[0]
}
</script>

<template>
  <!-- edit driver modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title=" دەستکاری شۆفێر"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="driverfirstname" class="form-label"> ناو</label>
                <input
                  type="text"
                  id="driverfirstname"
                  class="form-control"
                  placeholder="ناوی شۆفێر"
                  v-model="driverData.first_name"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.first_name">
                    <span>{{ errors.first_name[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="driverlastname" class="form-label"
                  >ناوی کۆتایی</label
                >
                <input
                  type="text"
                  id="driverlastname"
                  class="form-control"
                  placeholder="ناوی کۆتایی شۆفێر"
                  v-model="driverData.last_name"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.last_name">
                    <span>{{ errors.last_name[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="email" class="form-label">ئیمەیڵ</label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  placeholder="ئیمەیڵی شۆفێر"
                  v-model="driverData.email"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.email">
                    <span>{{ errors.email[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="phone" class="form-label">تەلەفۆن</label>
                <input
                  type="tel"
                  id="phone"
                  class="form-control"
                  placeholder="ژمارەی تەلەفۆنی شۆفێر"
                  v-model="driverData.phone_number"
                  @input="
                    convertPersianToEnglish(
                      $event.target.value,
                      driverData,
                      'phone_number'
                    )
                  "
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.phone_number">
                    <span>{{ errors.phone_number[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="drivernationalcode" class="form-label">
                  کۆدی نیشتمانی</label
                >
                <input
                  type="text"
                  id="drivernationalcode"
                  class="form-control"
                  placeholder="کۆدی نیشتمانی شۆفێر"
                  v-model="driverData.national_code"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.national_code">
                    <span>{{ errors.national_code[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="drivinglicensenumber" class="form-label">
                  ژمارەی مۆڵەتی شۆفێری</label
                >
                <input
                  type="text"
                  id="drivinglicensenumber"
                  class="form-control"
                  placeholder="ژمارەی مۆڵەتی شۆفێری"
                  v-model="driverData.driving_license_number"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.driving_license_number"
                  >
                    <span>{{ errors.driving_license_number[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="vehicleplatenumber" class="form-label"
                  >ژمارەی پلاکی ئۆتۆمبێل</label
                >
                <input
                  type="text"
                  id="vehicleplatenumber"
                  class="form-control"
                  placeholder="ژمارەی پلاکی ئۆتۆمبێل"
                  v-model="driverData.vehicle_plate_number"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.vehicle_plate_number"
                  >
                    <span>{{ errors.vehicle_plate_number[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="vehiclgpscode" class="form-label"
                  >کۆدی gps ی ئۆتۆمبێل</label
                >
                <input
                  type="text"
                  id="vehiclgpscode"
                  class="form-control"
                  placeholder="کۆدی gps ی ئۆتۆمبێل"
                  v-model="driverData.vehicle_gps_code"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.vehicle_gps_code">
                    <span>{{ errors.vehicle_gps_code[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label for="address" class="form-label">ناونیشان</label>
                <textarea
                  class="form-control"
                  id="address"
                  placeholder="ناونیشان"
                  rows="3"
                  v-model="driverData.address"
                ></textarea>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="nationalcard" class="form-label"
                  >وێنەی کارتی نیشتمانی</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="nationalcard"
                  @change="onNationalCardFileChange"
                />
                <div v-if="errors">
                  <div
                    class="invalid-feedback"
                    v-if="errors.national_card_photo"
                  >
                    <span>{{ errors.national_card_photo[0] }}</span>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <img
                  class="img-thumbnail"
                  alt="driverData.national_card_photo"
                  width="75"
                  :src="driverData.national_card_photo"
                  data-holder-rendered="true"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="drivinglicense" class="form-label"
                  >وێنەی مۆڵەتی شۆفێری</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="drivinglicense"
                  @change="onDrivingLicenseFileChange"
                />
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.category_image">
                    <span>{{ errors.category_image[0] }}</span>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <img
                  class="img-thumbnail"
                  alt="driverData.driving_license_photo"
                  width="75"
                  :src="driverData.driving_license_photo"
                  data-holder-rendered="true"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="status" class="form-label">دۆخی ئەکاونت</label>
                <select
                  class="form-control"
                  data-trigger
                  id="statusfield"
                  v-model="driverData.status"
                >
                  <option value="">دۆخی ئەکاونت</option>
                  <option value="0">ناچالاک</option>
                  <option value="1">چالاک</option>
                </select>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.status">
                    <span>{{ errors.status[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label for="isactive" class="form-label"
                  >دۆخی چالاکییەکان</label
                >
                <select
                  class="form-control"
                  data-trigger
                  id="isactive"
                  v-model="driverData.is_active"
                >
                  <option value="">دۆخی چالاکییەکان</option>
                  <option value="0">ناچالاک</option>
                  <option value="1">چالاک</option>
                </select>
                <div v-if="errors">
                  <div class="invalid-feedback" v-if="errors.is_active">
                    <span>{{ errors.is_active[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              داخستن
            </b-button>
            <b-button type="submit" variant="success">دەستکاری شۆفێر</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
