<script setup>
import { onMounted, ref, computed } from "vue"
import { useRoute } from "vue-router"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"
import { digitsEnToAr } from "@persian-tools/persian-tools"

import store from "../../state/store"
import useDebouncedFunction from "../../use/useDebouncedFunction"
import useSortData from "../../use/useSortData"
import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const allDriverOrders = computed(() => store.state.allDriverOrders)

const title = "ئۆردەرەکان شۆفێر"
const pageTitle = "شۆفێران"

const orderStatus = ref("")
const search = ref("")
const perPage = ref(10)

const route = useRoute()
const driverId = route.params.driverId

const getAllDriverOrders = async (url = null) => {
  try {
    await store.dispatch("getAllDriverOrders", {
      driverId,
      url,
      search: search.value,
      order_status: orderStatus.value,
      per_page: perPage.value,
      sort_field: sortField.value,
      sort_direction: sortDirection.value,
    })
  } catch (error) {
    console.error(error)
    return null
  }
}

const { handleInput } = useDebouncedFunction(getAllDriverOrders)

onMounted(async () => {
  getAllDriverOrders()
})

const { sortField, sortDirection, sortData } = useSortData(getAllDriverOrders)

const sortAllDriverOrders = (field) => {
  sortData(field)
  getAllDriverOrders()
}

const statusLabels = {
  PENDING: "هەڵپەسێردراو",
  SENDING: "ناردن",
  DELIVERED: "گەیاندی",
  CANCELLED: "هەڵوەشاوەتەوە",
}

const formattedPrices = (value) => {
  return new Intl.NumberFormat("ar-IQ").format(value)
}

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getAllDriverOrders(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="allDriverOrdersList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">ئۆردەرەکان شۆفێر</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2"></div>
              </b-col>
            </b-row>
          </b-card-header>

          <b-card-body class="border-bottom-dashed border-bottom">
            <b-row class="g-3">
              <b-col xl="7">
                <div class="search-box">
                  <input
                    v-model="search"
                    @input="handleInput"
                    type="text"
                    class="form-control search"
                    placeholder="گەڕان بە کۆدی بەدواداچوون..."
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </b-col>

              <b-col xl="5">
                <b-row class="g-3">
                  <b-col sm="6">
                    <select
                      @change="getAllDriverOrders(null)"
                      v-model="orderStatus"
                      class="form-select mb-3"
                      aria-label="Default select example"
                    >
                      <option value="" selected>دۆخی ئۆردەرەکه هەڵبژێرە</option>
                      <option
                        v-for="(label, value) in statusLabels"
                        :key="value"
                        :value="value"
                      >
                        {{ label }}
                      </option>
                    </select>
                  </b-col>

                  <b-col sm="6">
                    <select
                      @change="getAllDriverOrders(null)"
                      v-model="perPage"
                      class="form-select mb-3"
                      aria-label="Default select example"
                    >
                      <option value="" selected>
                        ژمارەی ڕیزەکان پیشان بدە
                      </option>
                      <option value="2">2</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body>
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="allDriverOrdersTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <TableHeaderCell
                        field="tracking_code"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('tracking_code')"
                      >
                        کۆدی بەدواداچوون
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="fuel_type_name"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('fuel_type_name')"
                      >
                        ناوی جۆری سووتەمەنی
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="fuel_price_per_liter"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('fuel_price_per_liter')"
                      >
                        نرخی سووتەمەنی بۆ هەر لیترێک
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="fuel_amount"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('fuel_amount')"
                      >
                        بڕی سووتەمەنی
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="fuel_cost"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('fuel_cost')"
                      >
                        تێچووی سووتەمەنی
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="delivery_charge"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('delivery_charge')"
                      >
                        کرێی گەیاندن
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="total_cost"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('total_cost')"
                      >
                        تێچوونی گشتی
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="order_status"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('order_status')"
                      >
                        دۆخی ئۆردەرەکه
                      </TableHeaderCell>

                      <TableHeaderCell
                        field="created_at"
                        :sort-field="sortField"
                        :sort-direction="sortDirection"
                        @click="sortAllDriverOrders('created_at')"
                      >
                        دروستکراوە لە
                      </TableHeaderCell>
                    </tr>
                  </thead>

                  <tbody
                    class="list"
                    v-if="
                      allDriverOrders.loading || !allDriverOrders.data.length
                    "
                  >
                    <tr>
                      <td colspan="10">
                        <div class="text-center">
                          <b-spinner
                            v-if="allDriverOrders.loading"
                            variant="dark"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner>
                          <div v-else>
                            <lottie
                              class="avatar-xl"
                              colors="primary:#121331,secondary:#08a88a"
                              :options="defaultOptions"
                              :height="75"
                              :width="75"
                            />
                            <h5 class="mt-2">ئۆردەرەکه نیە!</h5>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="list" v-else>
                    <tr
                      v-for="(item, index) of allDriverOrders.data"
                      :key="index"
                    >
                      <td class="tracking-code">
                        <RouterLink
                          :to="{
                            name: 'orders.details',
                            params: { id: item.id },
                          }"
                          class="link-secondary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                          target="_blank"
                          >{{ item.tracking_code }}
                        </RouterLink>
                      </td>

                      <td class="fuel_type-name">
                        {{ item.fuel_type_name }}
                      </td>

                      <td class="fuel-price-per-liter">
                        {{ formattedPrices(item.fuel_price_per_liter) }}
                      </td>

                      <td class="fuel-amount">
                        {{ digitsEnToAr(item.fuel_amount) }}
                      </td>

                      <td class="fuel-cost">
                        {{ formattedPrices(item.fuel_cost) }}
                      </td>

                      <td class="delivery-charge">
                        {{ formattedPrices(item.delivery_charge) }}
                      </td>

                      <td class="total-cost">
                        {{ formattedPrices(item.total_cost) }}
                      </td>

                      <td class="status">
                        <span
                          class="badge text-uppercase"
                          :class="{
                            'bg-warning-subtle text-warning':
                              item.orderStatus == 'PENDING',
                            'bg-primary-subtle text-primary':
                              item.orderStatus == 'SENDING',
                            'bg-success-subtle text-success':
                              item.orderStatus == 'DELIVERED',
                            'bg-danger-subtle text-danger':
                              item.orderStatus == 'CANCELLED',
                          }"
                          >{{ statusLabels[item.orderStatus] }}</span
                        >
                      </td>
                      <td class="create_at">
                        {{ digitsEnToAr(item.created_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="d-flex justify-content-end"
                v-if="allDriverOrders.total > allDriverOrders.limit"
              >
                <div class="pagination-wrap hstack gap-2" style="display: flex">
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(link, i) of allDriverOrders.links"
                      :key="i"
                      class="page-item"
                      :class="{
                        disabled: !link.url,
                        active: link.active || !link.url,
                      }"
                    >
                      <span
                        class="page-link"
                        v-if="link.active"
                        v-html="link.label"
                      ></span>

                      <a
                        v-else
                        href="#"
                        @click="getForPage($event, link)"
                        class="page-link"
                        v-html="link.label"
                      >
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
