import axiosClient from "../../axios";

/**
 * Users functions
 */
export async function createUser({ commit }, user) {
    return await axiosClient.post('/admin/users', user)
}

export async function getUsers({ commit, state }, { url = null, search = '', status, created_at, per_page, sort_field, sort_direction } = {}) {
    commit('setUsers', [true])
    url = url || "/admin/users"
    const params = {
        per_page: state.users.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, status, created_at, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setUsers', [false, response.data])
        })
        .catch(() => {
            commit('setUsers', [false])
        })
}

export async function getUser({ commit }, id) {
    return await axiosClient.get(`/admin/users/${id}`)
}

export async function updateUser({ commit }, user) {
    return await axiosClient.put(`/admin/users/${user.id}`, user);
}

export async function getVehiclesByUser({ commit }, userId) {
    return await axiosClient.get(`/admin/user/${userId}/vehicles`)
}

/**
 * Drivers functions
 */
export async function createDriver({ commit }, driver) {

    const form = new FormData();

    form.append('first_name', driver.first_name || '');
    form.append('last_name', driver.last_name || '');
    form.append('phone_number', driver.phone_number || '');
    form.append('email', driver.email || '');

    form.append('national_code', driver.national_code || '');
    form.append('driving_license_number', driver.driving_license_number || '');
    form.append('address', driver.address || '');
    form.append('vehicle_plate_number', driver.vehicle_plate_number || '');
    form.append('vehicle_gps_code', driver.vehicle_gps_code || '');


    form.append('status', parseInt(driver.status) ? 1 : 0);
    form.append('is_active', parseInt(driver.is_active) ? 1 : 0);

    if (driver.national_card_photo instanceof File) {
        form.append('national_card_photo', driver.national_card_photo);
    } else {
        form.append('national_card_photo', '');
    }

    if (driver.driving_license_photo instanceof File) {
        form.append('driving_license_photo', driver.driving_license_photo);
    } else {
        form.append('driving_license_photo', '');
    }

    driver = form;
    return await axiosClient.post('/admin/drivers', driver)
}

export async function getDrivers({ commit, state }, { url = null, search = '', status, created_at, per_page, sort_field, sort_direction } = {}) {
    commit('setDrivers', [true])
    url = url || "/admin/drivers"
    const params = {
        per_page: state.drivers.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, status, created_at, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setDrivers', [false, response.data])
        })
        .catch(() => {
            commit('setDrivers', [false])
        })
}

export async function getDriver({ commit }, id) {
    return await axiosClient.get(`/admin/drivers/${id}`)
}

export async function updateDriver({ commit }, driver) {

    console.log(driver);

    const userId = driver.id
    const driverInfoId = driver.driver_info_id

    const form = new FormData();

    form.append('first_name', driver.first_name || '');
    form.append('last_name', driver.last_name || '');
    form.append('phone_number', driver.phone_number || '');
    form.append('email', driver.email || '');

    form.append('national_code', driver.national_code || '');
    form.append('driving_license_number', driver.driving_license_number || '');
    form.append('address', driver.address || '');
    form.append('vehicle_plate_number', driver.vehicle_plate_number || '');
    form.append('vehicle_gps_code', driver.vehicle_gps_code || '');


    form.append('status', parseInt(driver.status) ? 1 : 0);
    form.append('is_active', parseInt(driver.is_active) ? 1 : 0);

    if (driver.national_card_photo instanceof File) {
        form.append('national_card_photo', driver.national_card_photo);
    } else {
        form.append('national_card_photo', '');
    }

    if (driver.driving_license_photo instanceof File) {
        form.append('driving_license_photo', driver.driving_license_photo);
    } else {
        form.append('driving_license_photo', '');
    }

    form.append('_method', 'PUT');
    driver = form;

    return axiosClient.post(`/admin/drivers/${userId}/${driverInfoId}`, driver)
}

/**
 * FuelTypes functions
 */
export async function createFuelType({ commit }, fuelType) {
    return await axiosClient.post('/admin/fuel-types', fuelType)
}

export async function getFuelTypes({ commit, state }, { url = null, search = '', status, per_page, sort_field, sort_direction } = {}) {
    commit('setFuelType', [true])
    url = url || "/admin/fuel-types"
    const params = {
        per_page: state.fuelTypes.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, status, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setFuelType', [false, response.data])
        })
        .catch(() => {
            commit('setFuelType', [false])
        })
}

export async function getFuelType({ commit }, id) {
    return await axiosClient.get(`/admin/fuel-types/${id}`)
}

export async function updateFuelType({ commit }, fuelType) {
    return await axiosClient.put(`/admin/fuel-types/${fuelType.id}`, fuelType);
}

/**
 * Driver's orders functions
 */
export async function getAllDriverOrders({ commit, state }, { url = null, driverId, search = '', order_status, per_page, sort_field, sort_direction } = {}) {
    commit('setAllDriverOrders', [true])
    url = url || `/admin/driver/${driverId}/all-orders`
    const params = {
        per_page: state.allDriverOrders.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, order_status, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setAllDriverOrders', [false, response.data])
            return response
        })
        .catch(() => {
            commit('setAllDriverOrders', [false])
        })
}

export async function getOrder({ commit }, id) {
    return await axiosClient.get(`/admin/driver/orders/${id}`)
}

/**
 * Messages functions
 */
export async function getUserMessages({ commit }, userId) {
    return await axiosClient.get(`/admin/user-messages/${userId}`)
}

export async function replyToMessage({ commit }, replyMessage) {
    return await axiosClient.post('/admin/reply-message', replyMessage)
}

export async function getMessages({ commit, state }, { url = null, search = '', unread_messages, per_page, sort_field, sort_direction } = {}) {
    commit('setMessages', [true])
    url = url || "/admin/users-messages"
    const params = {
        per_page: state.messages.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, unread_messages, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setMessages', [false, response.data])
        })
        .catch(() => {
            commit('setMessages', [false])
        })
}

export async function getMessageCount({ commit }) {
    return await axiosClient.get('/admin/messages/count')
}

/**
 * Notifications functions
 */
export async function createNotification({ commit }, notification) {
    return await axiosClient.post('/admin/notifications', notification)
}

export async function getNotifications({ commit, state }, { url = null, search = '', per_page, sort_field, sort_direction } = {}) {
    commit('setNotification', [true])
    url = url || "/admin/notifications"
    const params = {
        per_page: state.notifications.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setNotification', [false, response.data])
        })
        .catch(() => {
            commit('setNotification', [false])
        })
}

export function deleteNotification({ commit }, notification) {
    return axiosClient.delete(`/admin/notifications/${notification.id}`)
}

/**
 * Profile functions
 */
export function updateProfileInfo({ commit }, { user }) {

    const form = new FormData();

    form.append('first_name', user.value.name);
    form.append('last_name', user.value.surname);
    form.append('email', user.value.email);
    form.append('phone_number', user.value.phone);


    form.append('_method', 'PUT');
    user = form;

    return axiosClient.post('/admin/profile/edit', user)
}

export async function changePassword({ commit }, passwordData) {
    return await axiosClient.put('/admin/change-password', passwordData);
}