<script setup>
import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import { digitsEnToAr } from "@persian-tools/persian-tools"

import store from "../../state/store"

const title = "وردەکارییەکانی ئۆردەرەکه"
const pageTitle = "ئۆردەرەکان شۆفێر"

const route = useRoute()
const id = route.params.id

const orderDetails = ref([])
const userInfo = ref()

const statusLabels = {
  PENDING: "هەڵپەسێردراو",
  SENDING: "ناردن",
  DELIVERED: "گەیاندی",
  CANCELLED: "هەڵوەشاوەتەوە",
}

onMounted(async () => {
  const { data } = await store.dispatch("getOrder", id)
  orderDetails.value = {
    ...data.data,
  }

  const userData = await store.dispatch(
    "getUser",
    orderDetails.value.vehicle.user_id
  )
  userInfo.value = {
    ...userData.data.data,
  }
})

const formattedPrices = (value) => {
  return new Intl.NumberFormat("ar-IQ").format(value)
}

const parseColor = (color) => {
  try {
    const parsedColor = JSON.parse(color)
    return parsedColor
  } catch (error) {
    console.error("Error parsing color:", error)
    return { colorCode: "", colorName: "" }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col xl="9">
        <b-card no-body>
          <b-card-header>
            <div class="d-flex align-items-center">
              <h5 class="card-title flex-grow-1 mb-0">
                کۆدی بەدواداچوون:
                {{ orderDetails.trackingCode ? orderDetails.trackingCode : "" }}
              </h5>
            </div>
          </b-card-header>
          <b-card-body>
            <div class="table-responsive table-card">
              <table
                class="table table-nowrap align-middle table-borderless mb-0"
              >
                <thead class="table-light text-muted">
                  <tr>
                    <th scope="col">ناوی جۆری سووتەمەنی</th>
                    <th scope="col" class="text-center">نرخی هەر لیترێک</th>
                    <th scope="col" class="text-center">بڕی سووتەمەنی</th>
                    <th scope="col" class="text-center">تێچووی سووتەمەنی</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                        orderDetails.fuelTypeName
                          ? orderDetails.fuelTypeName
                          : ""
                      }}
                    </td>

                    <td class="fw-medium text-center">
                      {{
                        orderDetails.fuelPricePerLiter
                          ? formattedPrices(orderDetails.fuelPricePerLiter)
                          : ""
                      }}
                    </td>

                    <td class="fw-medium text-center">
                      {{
                        orderDetails.fuelAmount
                          ? digitsEnToAr(orderDetails.fuelAmount)
                          : ""
                      }}
                    </td>

                    <td class="fw-medium text-center">
                      {{
                        orderDetails.fuelCost
                          ? formattedPrices(orderDetails.fuelCost)
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr class="border-top border-top-dashed">
                    <td colspan="3"></td>
                    <td colspan="2" class="fw-medium p-0">
                      <table class="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td>کرێی گەیاندن:</td>
                            <td class="text-end">
                              {{
                                orderDetails.deliveryCharge
                                  ? formattedPrices(orderDetails.deliveryCharge)
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr class="border-top border-top-dashed">
                            <th scope="row">تێچوونی گشتی (IQD):</th>
                            <th class="text-end">
                              {{
                                orderDetails.totalCost
                                  ? formattedPrices(orderDetails.totalCost)
                                  : ""
                              }}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-header>
            <div class="d-sm-flex align-items-center">
              <h5 class="card-title flex-grow-1 mb-0">دۆخی ئۆردەرەکه</h5>
              <div class="flex-shrink-0 mt-2 mt-sm-0">
                <b-link
                  href="javascript:void(0);"
                  class="btn btn-soft-info btn-sm mt-2 mt-sm-0 shadow-none"
                  ><i class="ri-information-line align-middle me-1"></i>
                  {{
                    orderDetails.orderStatus
                      ? statusLabels[orderDetails.orderStatus]
                      : ""
                  }}</b-link
                >
              </div>
            </div>
          </b-card-header>
        </b-card>
      </b-col>
      <b-col xl="3">
        <b-card no-body>
          <b-card-header>
            <div class="d-flex">
              <h5 class="card-title flex-grow-1 mb-0">زانیاریه‌كانی كڕیار</h5>
              <div class="flex-shrink-0">
                <b-link
                  href="javascript:void(0);"
                  class="link-secondary"
                ></b-link>
              </div>
            </div>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled mb-0 vstack gap-3">
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h6 class="fs-14 mb-1">
                      {{
                        userInfo
                          ? userInfo.first_name + " " + userInfo.last_name
                          : ""
                      }}
                    </h6>
                    <p class="text-muted mb-0">کڕیار</p>
                  </div>
                </div>
              </li>
              <li>
                <i class="ri-phone-line me-2 align-middle text-muted fs-16"></i
                ><span dir="ltr">{{
                  userInfo ? userInfo.phone_number : ""
                }}</span>
              </li>
            </ul>
          </b-card-body>
        </b-card>

        <b-card no-body>
          <b-card-header>
            <div class="d-flex">
              <h5 class="card-title flex-grow-1 mb-0">
                تایبەتمەندیەکانی ئۆتۆمبێل
              </h5>
              <div class="flex-shrink-0">
                <b-link
                  href="javascript:void(0);"
                  class="link-secondary"
                ></b-link>
              </div>
            </div>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled mb-0 vstack gap-3">
              <li>
                <strong>جۆری ئۆتۆمبێل:</strong>
                {{
                  orderDetails.vehicle ? orderDetails.vehicle.vehicle_brand : ""
                }}
              </li>
              <li>
                <strong>ڕەنگی ئۆتۆمبێل:</strong>
                <span
                  v-if="orderDetails.vehicle"
                  :style="{
                    color: parseColor(orderDetails.vehicle.vehicle_color)
                      .colorCode,
                    textShadow: '0px 0px 1px #000',
                  }"
                  class="fw-medium px-1"
                  >{{
                    parseColor(orderDetails.vehicle.vehicle_color).colorName
                  }}</span
                >
              </li>

              <li>
                <strong>جۆری پلاکی:</strong>
                {{
                  orderDetails.vehicle ? orderDetails.vehicle.plate_type : ""
                }}
              </li>
              <li>
                <strong>ڕەنگی پلاکی:</strong>
                <span
                  v-if="orderDetails.vehicle"
                  :style="{
                    color: parseColor(orderDetails.vehicle.plate_color)
                      .colorCode,
                    textShadow: '0px 0px 1px #000',
                  }"
                  class="fw-medium px-1"
                  >{{
                    parseColor(orderDetails.vehicle.plate_color).colorName
                  }}</span
                >
              </li>
              <li>
                <strong>ژمارەی پلاکی:</strong>
                {{
                  orderDetails.vehicle ? orderDetails.vehicle.plate_number : ""
                }}
              </li>
            </ul>
          </b-card-body>
        </b-card>

        <b-card no-body>
          <b-card-header>
            <h5 class="card-title mb-0">ناونیشان</h5>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled vstack gap-2 fs-13 mb-0">
              <li>
                <i class="ri-map-pin-line align-middle me-2 text-muted"></i>
                <span dir="ltr">{{
                  orderDetails ? orderDetails.deliveryAddress : ""
                }}</span>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
