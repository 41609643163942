<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useFuelTypes from "../../use/useFuelTypes"
import usePersianTools from "../../use/usePersianTools"

const props = defineProps({
  fuelType: {
    required: true,
    type: Object,
  },
})

const fuelTypeData = ref({
  id: props.fuelType.id,
  name: props.fuelType.name,
  price_per_liter: props.fuelType.price_per_liter,
  description: props.fuelType.description,
  status: props.fuelType.status,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useFuelTypes(
  props,
  emit,
  fuelTypeData
)

const { convertPersianToEnglish } = usePersianTools()

onUpdated(() => {
  fuelTypeData.value = {
    id: props.fuelType.id,
    name: props.fuelType.name,
    price_per_liter: props.fuelType.price_per_liter,
    description: props.fuelType.description,
    status: props.fuelType.status,
  }
})
</script>

<template>
  <!-- edit fuelType modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="دەستکاری سووتەمەنی"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="name" class="form-label"> ناو</label>
            <input
              type="text"
              id="name"
              class="form-control"
              placeholder="ناوی سووتەمەنی"
              v-model="fuelTypeData.name"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.name">
                <span>{{ errors.name[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="priceperliter" class="form-label"
              >نرخی هەر لیترێک</label
            >
            <input
              type="text"
              id="priceperliter"
              class="form-control"
              placeholder="نرخی هەر لیترێک"
              v-model="fuelTypeData.price_per_liter"
              @input="
                convertPersianToEnglish(
                  $event.target.value,
                  fuelTypeData,
                  'price_per_liter'
                )
              "
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.price_per_liter">
                <span>{{ errors.price_per_liter[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="description" class="form-label">وەسف</label>
            <input
              type="description"
              id="description"
              class="form-control"
              placeholder="وەسف"
              v-model="fuelTypeData.description"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.description">
                <span>{{ errors.description[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="status" class="form-label">دۆخ</label>
            <select
              class="form-control"
              data-trigger
              id="statusfield"
              v-model="fuelTypeData.status"
            >
              <option value="">دۆخ</option>
              <option value="0">ناچالاک</option>
              <option value="1">چالاک</option>
            </select>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.status">
                <span>{{ errors.status[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              داخستن
            </b-button>
            <b-button type="submit" variant="success"
              >دەستکاری سووتەمەنی</b-button
            >
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
