<script>
import { layoutComputed } from "@/state/helpers"

import { useCounts } from "@/use/useCounts"

export default {
  setup() {
    const { messageCount } = useCounts()

    return {
      messageCount,
    }
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    }
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {}
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.initActiveMenu()
    if (this.rmenu == "vertical" && this.layoutType == "twocolumn") {
      document.documentElement.setAttribute("data-layout", "vertical")
    }
    document.getElementById("overlay").addEventListener("click", () => {
      document.body.classList.remove("vertical-sidebar-enable")
    })

    window.addEventListener("resize", () => {
      if (this.layoutType == "twocolumn") {
        var windowSize = document.documentElement.clientWidth
        if (windowSize < 767) {
          document.documentElement.setAttribute("data-layout", "vertical")
          this.rmenu = "vertical"
          localStorage.setItem("rmenu", "vertical")
        } else {
          document.documentElement.setAttribute("data-layout", "vertical")
          this.rmenu = "twocolumn"
          localStorage.setItem("rmenu", "twocolumn")
          setTimeout(() => {
            this.initActiveMenu()
          }, 50)
        }
      }
    })
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse")

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation()
          let closestCollapse = collapse.parentElement.closest(".collapse")
          if (closestCollapse) {
            let siblingCollapses = closestCollapse.querySelectorAll(".collapse")
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show")
                siblingCollapse.parentElement.firstChild.setAttribute(
                  "aria-expanded",
                  "false"
                )
              }
            })
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = []
              let sibling = elem.parentNode.firstChild
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling)
                }
                sibling = sibling.nextSibling
              }
              return siblings
            }
            let siblings = getSiblings(collapse.parentElement)
            siblings.forEach((item) => {
              if (item.childNodes.length > 2) {
                item.firstElementChild.setAttribute("aria-expanded", "false")
                item.firstElementChild.classList.remove("active")
              }
              let ids = item.querySelectorAll("*[id]")
              ids.forEach((item1) => {
                item1.classList.remove("show")
                item1.parentElement.firstChild.setAttribute(
                  "aria-expanded",
                  "false"
                )
                item1.parentElement.firstChild.classList.remove("active")
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a")

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false")
                  })
                }
              })
            })
          }
        })

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation()
          let childCollapses = collapse.querySelectorAll(".collapse")
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse
            childCollapseInstance.classList.remove("show")
            childCollapseInstance.parentElement.firstChild.setAttribute(
              "aria-expanded",
              "false"
            )
          })
        })
      })
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path)
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300
      }
    },

    initActiveMenu() {
      const pathName = window.location.pathname
      const ul = document.getElementById("navbar-nav")
      if (ul) {
        const items = Array.from(ul.querySelectorAll("a.nav-link"))
        let activeItems = items.filter((x) => x.classList.contains("active"))
        this.removeActivation(activeItems)
        let matchingMenuItem = items.find((x) => {
          return x.getAttribute("href") === pathName
        })
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem)
        } else {
          var id = pathName.replace("/", "")
          if (id) document.body.classList.add("twocolumn-panel")
          this.activateIconSidebarActive(pathName)
        }
      }
    },

    updateMenu(e, event) {
      document.body.classList.remove("twocolumn-panel")
      const ul = document.getElementById("navbar-nav")
      if (ul) {
        const items = Array.from(ul.querySelectorAll(".show"))
        items.forEach((item) => {
          item.classList.remove("show")
        })
      }
      const icons = document.getElementById("two-column-menu")
      if (icons) {
        const activeIcons = Array.from(
          icons.querySelectorAll(".nav-icon.active")
        )
        activeIcons.forEach((item) => {
          item.classList.remove("active")
        })
      }
      document.getElementById(e).classList.add("show")
      event.target.classList.add("active")
      this.activateIconSidebarActive("#" + e)
    },

    removeActivation(items) {
      items.forEach((item) => {
        if (item.classList.contains("menu-link")) {
          if (!item.classList.contains("active")) {
            item.setAttribute("aria-expanded", false)
          }
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove("show")
          }
        }
        if (item.classList.contains("nav-link")) {
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove("show")
          }
          item.setAttribute("aria-expanded", false)
        }
        item.classList.remove("active")
      })
    },

    activateParentDropdown(item) {
      // navbar-nav menu add active
      item.classList.add("active")
      let parentCollapseDiv = item.closest(".collapse.menu-dropdown")
      if (parentCollapseDiv) {
        // to set aria expand true remaining
        parentCollapseDiv.classList.add("show")
        parentCollapseDiv.parentElement.children[0].classList.add("active")
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true"
        )
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          if (
            parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
              .previousElementSibling
          ) {
            if (
              parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .previousElementSibling.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
            ) {
              const grandparent = parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .previousElementSibling.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              this.activateIconSidebarActive(
                "#" + grandparent.getAttribute("id")
              )
              grandparent.classList.add("show")
            }
          }
          this.activateIconSidebarActive(
            "#" +
              parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .getAttribute("id")
          )

          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show")
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          )
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.classList.add("active")
          return false
        }
        this.activateIconSidebarActive(
          "#" + parentCollapseDiv.getAttribute("id")
        )
        return false
      }
      return false
    },

    activateIconSidebarActive(id) {
      var menu = document.querySelector(
        "#two-column-menu .simplebar-content-wrapper a[href='" +
          id +
          "'].nav-icon"
      )
      if (menu !== null) {
        menu.classList.add("active")
      }
    },
  },
}
</script>

<template>
  <BContainer fluid>
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'vertical' || layoutType === 'semibox'">
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'dashboard' }">
            <i class="mdi mdi-speedometer"></i>
            <span data-key="t-dashboard">{{ $t("t-dashboard") }}</span>
          </router-link>
        </li>
        <!-- end Dashboard Menu -->

        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'users' }">
            <i class="mdi mdi-account-group-outline"></i>
            <span data-key="t-users">{{ $t("t-users") }}</span>
          </router-link>
        </li>
        <!-- end users -->

        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'drivers' }">
            <i class="mdi mdi-car-estate"></i>
            <span data-key="t-drivers">{{ $t("t-drivers") }}</span>
          </router-link>
        </li>
        <!-- end drivers -->

        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'fuel.types' }">
            <i class="mdi mdi-fuel"></i>
            <span data-key="t-fuel-types">{{ $t("t-fuel-types") }}</span>
          </router-link>
        </li>
        <!-- end fuelTypes -->

        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'messages' }">
            <i class="mdi mdi-message-text-outline"></i>
            <span data-key="t-messages">
              {{ $t("t-messages") }}
            </span>
            <b-badge
              v-if="messageCount > 0"
              variant="danger"
              class="badge-pill"
              data-key="t-new"
              >{{ messageCount }}</b-badge
            >
          </router-link>
        </li>
        <!-- end messages -->

        <li class="nav-item">
          <router-link class="nav-link menu-link" :to="{ name: 'notifications' }">
            <i class="mdi mdi-bell-outline"></i>
            <span data-key="t-notifications">{{ $t("t-notifications") }}</span>
          </router-link>
        </li>
        <!-- end notifications -->
      </ul>
    </template>
  </BContainer>
</template>
