<script setup>
import { computed, onMounted, ref } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"
import { digitsEnToAr } from "@persian-tools/persian-tools"

import Swal from "sweetalert2"

import store from "../../state/store"

const notifications = computed(() => store.state.notifications)

import useNotifications from "../../use/useNotifications"
import useDebouncedFunction from "../../use/useDebouncedFunction"

import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const { search, perPage, sortField, sortDirection, getNotifications } =
  useNotifications()
const { handleInput } = useDebouncedFunction(getNotifications)

onMounted(() => {
  getNotifications()
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getNotifications(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

function sortNotifications(field) {
  if (field === sortField.value) {
    if (sortDirection.value === "desc") {
      sortDirection.value = "asc"
    } else {
      sortDirection.value = "desc"
    }
  } else {
    sortField.value = field
    sortDirection.value = "asc"
  }

  getNotifications()
}

const deleteNotification = (bnr) => {
  Swal.fire({
    title: "ئایا دڵنیایت ؟",
    text: "ئەگەر بیسڕیتەوە ناتوانیت وەریبگریتەوە!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34c38f",
    cancelButtonColor: "#f46a6a",
    confirmButtonText: "بەڵێ",
    cancelButtonText: "هەڵوەشاندنەوە",
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch("deleteNotification", bnr).then((res) => {
        // console.log(res)
        Swal.fire({
          title: "سڕاوەتەوە!",
          text: "ئاگادارییەکە سڕاوەتەوە.",
          icon: "success",
          confirmButtonText: "باشە",
        })
        getNotifications()
      })
    }
  })
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="9">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="تایتەڵ و دەق و..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="3">
        <b-row class="g-3">
          <b-col sm="12">
            <select
              @change="getNotifications(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>ژمارەی ڕیزەکان پیشان بدە</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="notificationTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="title"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortNotifications('title')"
              >
                تایتەڵ
              </TableHeaderCell>
              <TableHeaderCell
                field="message"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortNotifications('message')"
              >
                دەق ئاگادارکردنەوە
              </TableHeaderCell>

              <TableHeaderCell
                field="created_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortNotifications('created_at')"
              >
                بەرواری ناو تۆمارکردن
              </TableHeaderCell>

              <th class="sort" data-sort="action">ئۆپەراسیۆنەکە</th>
            </tr>
          </thead>
          <tbody
            class="list"
            v-if="notifications.loading || !notifications.data.length"
          >
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="notifications.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ ئاگادارکردنەوەیەک نییە!!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr
              v-for="(notification, index) of notifications.data"
              :key="index"
            >
              <td class="notification-title">{{ notification.title }}</td>
              <td class="notification-message">{{ notification.message }}</td>
              <td class="date" dir="ltr">
                {{ digitsEnToAr(notification.created_at) }}
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="لابردن"
                  >
                    <b-link
                      class="text-danger d-inline-block remove-item-btn"
                      @click="deleteNotification(notification)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </b-link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="notifications.total > notifications.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of notifications.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<style>
.vpd-icon-btn {
  margin-bottom: 0 !important;
  padding: 0.5rem 0.9rem !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.5rem 0.9rem !important;
}
</style>
